import logo from './logo.jpg';
import './App.css';
import ElectricityTracker from './ElectricityTracker';
import MinerActivity from './MinerActivity';
import CompanyIncome from './CompanyIncome';
import WemoAppliances from './WemoAppliances';
import PhilipsHue from './PhilipsHue';
import SongDownloader from './SongDownloader';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-problem-box">
          <div className="App-button-container">
            <div className="Header-Button-holder">
              
              <button>Fetch </button>
              <button>Play </button>
              <button>Stop </button>
            </div>
          </div>
          <div className="App-logo-container">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
        </div>
        <p className="jack-paragraph">
          Pleasetunein app
        </p>
        <SongDownloader/>
        <MinerActivity/>
        <ElectricityTracker/>
        <CompanyIncome/>
        <WemoAppliances/>
        <p className="jack-paragraph">
          Assign jobs and create scheduled tasks for automated devices
        </p>
        <ul>
          <li>MinerMonitor - Sends SMS from Dev Server-Printer Server</li>
          <li>remoteInternet - Sends internet speed data from remote house</li>
          <li>WhatYouWant4 - Sends SMS from Dev Server-Printer Server</li>
        </ul>
        <PhilipsHue/>
        <div className="jacks-big-container">
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Login to this app
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
