import React, { Component } from 'react';
import './App.css';


class ElectricityTracker extends Component {
    constructor(props){
      super(props);
    }
    render() {
      document.title = 'Electricity Tracker'
      return (
        <div className="Electricity-component">
          <div className="Electricity-container">
            <p className="jack-paragraph">
                To track Electricticy usage5
            </p>
            <div className="jack-container-blocks">
                <div className="jack-block">
                    March: 
                    <span className="jack-totals-span">$264.86</span>
                </div>
                <div className="jack-block">
                    April: 
                    <span className="jack-totals-span">$307.66</span>
                </div>
                <div className="jack-block">
                    May: 
                    <span className="jack-totals-span">$276.34</span>
                </div>
                <div className="jack-block">
                    June: 
                    
                </div>
            </div>
          </div>
        </div>
      );
    }
  }
  
  export default ElectricityTracker;
  