import React, { Component } from 'react';
import './App.css';


class MinerActivity extends Component {
    constructor(props){
      super(props);
    }
    render() {
      document.title = 'Miner Activity'
      return (
        <div className="-component">
          <div className="-container">
            <p className="jack-paragraph">
                Miner Activity
            </p>
            <div className="jack-container-blocks">
                <div className="jack-block">
                    Current Daily profitability: 
                    <span className="jack-totals-span">$7.74</span>
                </div>
                <div className="jack-block">
                    Today's profits:
                    <span className="jack-totals-span">$2.16</span>
                </div>
                <div className="jack-block">
                    Yesterday's profits: 
                    <span className="jack-totals-span">$276.34</span>
                </div>
                <div className="jack-block">
                    Nicehash wallet: 
                    <span className="jack-totals-span">$9.46</span>
                </div>
            </div>
          </div>
        </div>
      );
    }
  }
  
  export default MinerActivity;
  