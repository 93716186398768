import React, { Component } from 'react';
import './App.css';


class SongDownloader extends Component {
    constructor(props){
      super(props);
    }
    render() {
      document.title = 'Song Downloader'
      return (
        <div className="-component">
          <div className="-container">
            <p className="jack-paragraph">
              Song Playlists Loaded and tracked
            </p>
            <div className="jack-container-blocks">
                <div className="jack-block">
                    Youtube Song Playlists: 
                    <span className="jack-totals-span">7/28</span>
                </div>
                <div className="jack-block">
                    TIDAL Song Playlists: 
                    <span className="jack-totals-span">16/50</span>
                </div>
                <div className="jack-block">
                    Shazams Recently Added:  
                    <span className="jack-totals-span">4/100</span>
                </div> 
            </div>
          </div>
        </div>
      );
    }
  }
  
  export default SongDownloader;
  