import React, { Component } from 'react';
import './App.css';


class WemoAppliances extends Component {
    constructor(props){
      super(props);
    }
    render() {
      document.title = 'WemoAppliances'
      return (
        <div className="-component">
          <div className="-container">
          <p className="jack-paragraph">
            Turn on and off appliances
          </p>
          <div className="jack-container-blocks">
            <div className="jack-block income-green">
              Special Light: 
              <span className="jack-totals-span"><button>Toggle</button></span>
            </div>
            <div className="jack-block income-green">
              Server Dev: 
              <span className="jack-totals-span"><button>Toggle</button></span>
            </div>
            <div className="jack-block">
              Vampire TV: 
              <span className="jack-totals-span"><button>Toggle</button></span>
            </div>
            <div className="jack-block">
              Server 2246: 
              <span className="jack-totals-span"><button>Toggle</button></span>
            </div>
            <div className="jack-block">
              Server 2245: 
              <span className="jack-totals-span"><button>Toggle</button></span>
            </div>
          </div>
          </div>
        </div>
      );
    }
  }
  
  export default WemoAppliances;
  