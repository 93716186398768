import React, { Component } from 'react';
import './App.css';


class CompanyIncome extends Component {
    constructor(props){
      super(props);
    }
    render() {
      document.title = 'CompanyIncome'
      return (
        <div className="-component">
          <div className="-container">
          <p className="jack-paragraph">
            To track Company Income
          </p>
          <div className="jack-container-blocks">
            <div className="jack-block">
              March: 
              <span className="jack-totals-span income-green">$583.72</span>
            </div>
            <div className="jack-block">
              April: 
              <span className="jack-totals-span income-green">$478.95</span>
            </div>
            <div className="jack-block">
              May: 
              <span className="jack-totals-span income-green">$190.77</span>
            </div>
            <div className="jack-block">
              June: 
              
            </div>
          </div>
          </div>
        </div>
      );
    }
  }
  
  export default CompanyIncome;
  